<template>
    <li :key="id" class="relative group">
        <div
            v-if="isFeatured"
            class="absolute animate-glow -inset-0.5 bg-gradient-to-r from-cyan-300 to-cyan-600 rounded-lg blur group-hover:scale-[102%] opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-500"
        ></div>
        <div
            class="relative bg-white px-4 py-5 hover:bg-gray-50 sm:px-6 hover:scale-[102%] transition duration-500 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg"
        >
            <div
                v-if="isFeatured"
                class="absolute top-0 right-0 px-2 py-1 text-sm font-medium tracking-wide text-white uppercase bg-bluewood-600 rounded-tr-lg rounded-bl-lg"
            >
                Featured
            </div>
            <div
                class="relative flex flex-col gap-y-4 md:gap-y-0 md:flex-row justify-between gap-x-6"
            >
                <div class="flex gap-x-4 flex-col sm:flex-row space-y-4">
                    <div v-if="companyLogo" class="my-auto w-32 flex-shrink-0">
                        <img :src="companyLogo" :alt="companyName" />
                    </div>
                    <div class="min-w-0 space-y-2 flex-auto">
                        <h3
                            class="text-2xl capitalize font-gothic font-semibold leading-6 text-bluewood-950"
                        >
                            <Link :href="href">
                                <span class="absolute inset-0" />

                                {{ title }}
                            </Link>
                        </h3>
                        <div class="flex flex-wrap gap-2">
                            <p class="mt-1 text-sm leading-5 text-gray-500">
                                {{ companyName }}
                            </p>

                            <span
                                v-if="salaryRange"
                                class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium text-bluewood-900 ring-1 ring-inset ring-gray-200"
                            >
                                <BanknotesIcon
                                    v-if="salaryRange"
                                    class="w-5 h-5 text-bluewood-900"
                                />
                                {{ salaryRange }}
                            </span>
                        </div>

                        <Badges
                            :experience="experience"
                            :category="category"
                            :commitment="commitment"
                            :remoteStatus="remoteStatus"
                            :remoteLabel="remoteLabel"
                        />

                        <div v-if="benefits" class="flex flex-wrap gap-2">
                            <ListingBadge
                                v-for="benefit in benefits"
                                :key="benefit"
                                :label="benefit"
                            />
                        </div>

                        <p
                            v-if="postedDate"
                            class="text-sm leading-5 text-gray-500"
                        >
                            Posted {{ postedDateFromNow }}
                        </p>
                    </div>
                </div>
                <div class="relative flex items-center gap-x-4 flex-shrink-0">
                    <Link
                        class="rounded-md font-gothic bg-bluewood-600 px-3.5 py-2.5 text-lg text-white shadow-sm hover:bg-bluewood-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bluewood-600"
                        :href="href"
                    >
                        View Job Post
                    </Link>
                    <ApplyButton
                        :applyLink="applyLink"
                        :applyEmail="applyEmail"
                        :applyMethod="applyMethod"
                    />
                </div>
            </div>
        </div>
    </li>
</template>

<script setup>
import ListingBadge from "@/Components/Guest/Listings/ListingBadge.vue";
import { Link } from "@inertiajs/vue3";
import { BanknotesIcon } from "@heroicons/vue/24/outline/index.js";
import ApplyButton from "@/Components/Guest/ApplyButton.vue";
import Badges from "@/Components/Guest/Listings/Badges.vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const props = defineProps({
    listing: {
        type: Object,
        required: true,
    },
});

const {
    id,
    title,
    href,
    applyLink,
    applyEmail,
    applyMethod,
    location,
    companyName,
    category,
    postedDate,
    experience,
    commitment,
    remoteStatus,
    salaryRange,
    benefits,
    description,
    companyLogo,
    remoteLabel,
    requirements,
    isFeatured,
} = props.listing;

dayjs.extend(relativeTime);

let postedDateFromNow = dayjs(postedDate).fromNow();
</script>
