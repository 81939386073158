<template>
    <div v-if="avatars" class="flex items-center mt-6 justify-center space-x-6">
        <div
            class="-space-x-5 flex items-center relative [&>img]:border-4 [&>img]:border-white dark:[&>img]:border-black"
        >
            <img
                v-for="avatar in avatars"
                :src="avatar"
                alt="Avatar of one of our email list subscribers"
                class="rounded-full h-12 w-12"
            />
        </div>
        <span class="text-gray-700 dark:text-white text-lg">
            2000+ subscribers
        </span>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
    avatars: {
        type: Object,
        required: false,
    },
});

let avatars = props.avatars;

if (!avatars) {
    const page = usePage();
    avatars = computed(() => page.props.avatars);
}
</script>
